<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Recurrent Transactions</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="templatesData"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: null,
    templatesData: [],
    headers: [
      { text: "Template", value: "orcp.Code" },
      { text: "Document", value: "objecttype.DocumentName" },
      { text: "Plan Date", value: "PlanDate" },
      { text: "DraftEntry", value: "orcp.DraftEntry" },
      { text: "Created At", value: "created_at" },
    ],
  }),
  methods: {
    getTemplates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/recurringtransactions`)
        .then((res) => {
          self.loading = false;
          if (res.ResultCode == 1200) {
            self.templatesData = res.ResponseData;
          } else {
            this.$store.commit("loader", false);
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTemplates();
  },
};
</script>